import './styles.scss';

import { Col, Empty, Row } from 'antd';
import sparePartsImage from 'assets/img/spare-parts.webp';
import B2BecLink from 'components/B2BLink';
import { isValidArray } from 'libs/utils/array';
import { linkGenerator } from 'libs/utils/language';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  selectSubmenuAccessoriesAndDetergents,
  selectSubmenuCategories,
} from 'store/selectors/categorySelector';

import PermissionWrapper from '../../../HOCs/permissionWrapper';
import { MODULE_SHOP_NAVIGATION_RIGHTS } from '../../../libs/constants/modulerights';
import { selectShowSparePartLink } from '../../../store/selectors/userSelector';
import CustomButton from '../../CustomButton';
import getImageUrl from '../getImageUrl';
import { selectIsFuturetech } from 'store/selectors/configSelector';
import { IMAGE_SOURCES } from 'libs/constants';

function HeaderSubmenu({ setShowSubmenu }) {
  const { t } = useTranslation();
  const history = useHistory();
  const productMenuRef = useRef(null);
  const shouldShowSparePart = useSelector(selectShowSparePartLink);
  const categories = useSelector(selectSubmenuCategories);
  const accessoriesAndDetergents = useSelector(
    selectSubmenuAccessoriesAndDetergents
  );
  const isFutureTech = useSelector(selectIsFuturetech);

  const handleClickOutside = useCallback(
    (event) => {
      if (
        productMenuRef.current &&
        !productMenuRef.current.contains(event.target)
      ) {
        setShowSubmenu(false);
      }
    },
    [setShowSubmenu]
  );

  const handleClickLink = () => {
    setShowSubmenu(false);
  };

  const handleSeeCategoryOverview = () => {
    setShowSubmenu(false);
    history.push(linkGenerator('/categories'));
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, [handleClickOutside]);

  const renderSubmenuCategories = () => {
    if (isValidArray(categories?.subCategories)) {
      return (
        <div
          className="header-submenu__item__wrapper"
          key={categories?.categoryId}
        >
          <Row>
            <Col span={24} className="header-submenu__title">
              <h3>{categories?.categoryName}</h3>
            </Col>
          </Row>
          <Row gutter={10}>
            {categories?.subCategories?.map((subCategory) =>
              subCategory?.categoryName && subCategory?.categoryId ? (
                <Col
                  xs={12}
                  sm={8}
                  md={6}
                  lg={4}
                  xl={4}
                  className="header-submenu__item"
                  key={subCategory?.categoryId}
                >
                  <B2BecLink
                    to={`/category/${subCategory?.categoryId}`}
                    className="header-submenu__item__link"
                    style={{
                      backgroundImage: getImageUrl(subCategory?.menuImages),
                    }}
                    onClick={handleClickLink}
                  >
                    {subCategory?.categoryName}
                  </B2BecLink>
                </Col>
              ) : null
            )}
          </Row>
        </div>
      );
    }

    return <Empty description={t('noData')} />;
  };

  const getCategoryDetails = (idx, isFutureTech) => {
    let to = '/categories/accessories';
    let translatedCategoryName = t('searchResultsPage.header.accessories');
    let imageSrc = `url(${IMAGE_SOURCES.ACCESSORIES})`;

    if (idx === 1) {
      translatedCategoryName = t('searchResultsPage.header.detergents');
      to = '/categories/detergents';
      imageSrc = `url(${IMAGE_SOURCES.DETERGENTS})`;
    }

    if (isFutureTech) {
      imageSrc = `url(${require(`../../../assets/img/futuretech/${
        idx === 0 ? 'accessories' : 'detergents'
      }.webp`)})`;
    }

    return { to, translatedCategoryName, imageSrc };
  };

  const renderSubmenuAccessoriesAndDetergents = () => {
    if (isValidArray(accessoriesAndDetergents)) {
      return accessoriesAndDetergents?.map((category, idx) => {
        const { to, translatedCategoryName, imageSrc } = getCategoryDetails(
          idx,
          isFutureTech
        );

        return (
          <Col
            xs={12}
            sm={8}
            md={6}
            lg={4}
            xl={4}
            className="header-submenu__item"
            key={category?.categoryId}
          >
            <B2BecLink
              to={to}
              onClick={handleClickLink}
              className="header-submenu__item__link"
              style={{
                backgroundImage: imageSrc,
              }}
            >
              {translatedCategoryName}
            </B2BecLink>
          </Col>
        );
      });
    }

    return <Empty description={t('noData')} />;
  };

  return (
    <div className="header-submenu fadeInDown" ref={productMenuRef}>
      <div className="header-submenu__container">
        {renderSubmenuCategories()}
        <Row>
          <Col span={24} className="header-submenu__title">
            <h3>{t('proEquipment')}</h3>
          </Col>
        </Row>
        <Row gutter={10}>
          {renderSubmenuAccessoriesAndDetergents()}
          {shouldShowSparePart && (
            <PermissionWrapper
              permission={MODULE_SHOP_NAVIGATION_RIGHTS.OPEN_SPARE_PART_SHOP}
            >
              <Col
                xs={12}
                sm={8}
                md={6}
                lg={4}
                xl={4}
                className="header-submenu__item"
              >
                <B2BecLink
                  to="/spare-parts"
                  className="header-submenu__item__link"
                  style={{
                    backgroundImage: `url(${sparePartsImage})`,
                  }}
                  onClick={handleClickLink}
                >
                  {t('searchResultsPage.header.spareParts')}
                </B2BecLink>
              </Col>
            </PermissionWrapper>
          )}
        </Row>
        <p className="header-submenu__bottom-link">
          <CustomButton type="ghost" onClick={handleSeeCategoryOverview}>
            {t('categoryOverviewPage')}
          </CustomButton>
        </p>
      </div>
    </div>
  );
}

HeaderSubmenu.propTypes = {
  setShowSubmenu: PropTypes.func,
};

HeaderSubmenu.defaultProps = {
  setShowSubmenu: () => {},
};

export default HeaderSubmenu;
